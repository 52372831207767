'use client'

import { cn } from '@/web/libs/utils'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cva, type VariantProps } from 'class-variance-authority'
import { Check } from 'lucide-react'
import * as React from 'react'

const rootVariants = cva(
  'peer h-4 w-4 shrink-0 border ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 group [&:has([data-state=unchecked])_>_*]:opacity-0 [&:has([data-state=unchecked])_>_*]:hover:opacity-50',
  {
    variants: {
      variant: {
        default: 'rounded-sm',
        round: 'rounded-full',
      },
      theme: {
        custom: '',
        default:
          'border-muted-content hover:border-primary data-[state=checked]:border-primary data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground data-[state=indeterminate]:text-primary data-[state=indeterminate]:hover:bg-primary/25 data-[state=unchecked]:hover:bg-primary/25 data-[checkbox-state=checked]:border-primary data-[checkbox-state=checked]:bg-primary data-[checkbox-state=checked]:text-primary-foreground data-[checkbox-state=indeterminate]:text-primary data-[checkbox-state=indeterminate]:hover:bg-primary/25 data-[checkbox-state=unchecked]:hover:bg-primary/25',
        success:
          'border-muted-content hover:border-success data-[state=checked]:border-success data-[state=checked]:bg-success data-[state=checked]:text-success-content data-[state=indeterminate]:text-success data-[state=indeterminate]:hover:bg-success/25 data-[state=unchecked]:hover:bg-success/25 data-[checkbox-state=checked]:border-success data-[checkbox-state=checked]:bg-success data-[checkbox-state=checked]:text-success-content data-[checkbox-state=indeterminate]:text-success data-[checkbox-state=indeterminate]:hover:bg-success/25 data-[checkbox-state=unchecked]:hover:bg-success/25',
        destructive:
          'border-muted-content hover:border-destructive data-[state=checked]:border-destructive data-[state=checked]:bg-destructive data-[state=checked]:text-destructive-foreground data-[state=indeterminate]:text-destructive data-[state=indeterminate]:hover:bg-destructive/25 data-[state=unchecked]:hover:bg-destructive/25 data-[checkbox-state=checked]:border-destructive data-[checkbox-state=checked]:bg-destructive data-[checkbox-state=checked]:text-destructive-foreground data-[checkbox-state=indeterminate]:text-destructive data-[checkbox-state=indeterminate]:hover:bg-destructive/25 data-[checkbox-state=unchecked]:hover:bg-destructive/25',
        warning:
          'border-muted-content hover:border-warning data-[state=checked]:border-warning data-[state=checked]:bg-warning data-[state=checked]:text-warning-foreground data-[state=indeterminate]:text-warning data-[state=indeterminate]:hover:bg-warning/25 data-[state=unchecked]:hover:bg-warning/25 data-[checkbox-state=checked]:border-warning data-[checkbox-state=checked]:bg-warning data-[checkbox-state=checked]:text-warning-foreground data-[checkbox-state=indeterminate]:text-warning data-[checkbox-state=indeterminate]:hover:bg-warning/25 data-[checkbox-state=unchecked]:hover:bg-warning/25',
      },
      // FIXME: Compounding with asChild in other Radix component causes overwriting
      // of data-state
      // @see https://github.com/radix-ui/primitives/discussions/560
      state: {
        checked: '',
        indeterminate: '',
        unchecked: '',
      },
      size: {
        xs: 'h-3 w-3',
        sm: 'h-4 w-4',
        md: 'h-6 w-6',
        lg: 'h-8 w-8',
      },
    },
    defaultVariants: {
      variant: 'default',
      theme: 'default',
      size: 'sm',
    },
  }
)

const indicatorVariants = cva(
  'flex items-center justify-center text-current h-full',
  {
    variants: {},
    defaultVariants: {},
  }
)

const checkVariants = cva('', {
  variants: {
    size: {
      xs: 'h-2 w-2',
      sm: 'h-3 w-3',
      md: 'h-4 w-4',
      lg: 'h-6 w-6',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
})

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> &
    VariantProps<typeof rootVariants>
>(({ className, size, variant, theme, children, state, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    data-checkbox-state={state}
    className={cn(rootVariants({ size, theme, variant, state }), className)}
    {...props}>
    <CheckboxPrimitive.Indicator
      className={cn(indicatorVariants({}))}
      forceMount>
      {children !== undefined ? (
        children
      ) : (
        <Check className={checkVariants({ size })} />
      )}
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
